<template>
  <div>
    <h1>Regiões</h1>

    <!-- Create Region -->
    <v-btn
      v-if="!loadingRegions"
      color="secondary"
      @click="addRegionModal = true"
      >Nova Região</v-btn
    >
    <v-dialog v-model="addRegionModal">
      <v-progress-linear v-if="savingRegion" color="purple" indeterminate />
      <v-card>
        <v-card-title>Nova Região</v-card-title>
        <v-card-text>
          <v-form ref="regionForm" @submit.prevent="">
            <v-text-field
              focus
              v-model="name"
              :rules="requiredRules"
              label="Região"
            ></v-text-field>
            <v-btn
              @click="saveRegion()"
              :loading="savingRegion"
              color="primary"
              type="submit"
              >Salvar</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <v-data-table
      :headers="headers"
      :items="regions"
      hide-default-footer
      disable-pagination
      disable-sort
      :loading="loadingRegions"
    >

    </v-data-table>
  </div>
</template>

<script>
import http from "@/modules/http";
export default {
  data() {
    return {
      headers: [{text: 'Região', value: 'name'}],
      addRegionModal: false,
      savingRegion: false,
      regions: [],
      loadingRegions: false,
      requiredRules: [(v) => !!v || "O nome é obrigatório!"],
      name: "",
    };
  },
  mounted() {
    this.updateRegions();
  },
  methods: {
    updateRegions() {
      this.loadingRegions = true;
      http
        .get("api/regions")
        .then((res) => {
          this.regions = res.data;
          this.loadingRegions = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingRegions = false;
        });
    },
    saveRegion() {
      this.savingRegion = true;
      if (this.$refs.regionForm.validate()) {
        http
          .post("api/regions", {
            name: this.name,
          })
          .then((res) => {
            console.log(res.data);
            this.savingRegion = false;
            this.$side({
              type: "success",
              msg: "Salvo com sucesso",
            });
            this.addRegionModal = false;
            this.name = "";
            this.$refs.regionForm.reset();
            this.updateRegions();
          })
          .catch((err) => {
            err.response.data.errors.name.forEach((e) => {
              this.$side({
                type: "error",
                msg: e,
              });
            });
            this.savingRegion = false;
            this.name = "";
            this.$refs.regionForm.reset();
          });
      }
    },
  },
};
</script>